import { createContext, useContext, useRef } from 'react';

import { createFocusEmitter } from './focusEmitter';

import type { FocusEmitter } from './focusEmitter';
import type { FC, PropsWithChildren } from 'react';

const FocusEmitterContext = createContext<FocusEmitter | null>(null);

export const FocusCellProvider: FC<PropsWithChildren> = ({ children }) => {
  const emitter = useRef<FocusEmitter>();

  if (!emitter.current) {
    emitter.current = createFocusEmitter();
  }

  return (
    <FocusEmitterContext.Provider value={emitter.current}>
      {children}
    </FocusEmitterContext.Provider>
  );
};

export const useFocusCell = <Meta,>() => {
  const emitter = useContext(FocusEmitterContext);

  if (!emitter) {
    throw new Error('useFocusCell must be used within FocusCellProvider');
  }

  return emitter as FocusEmitter<Meta>;
};
