import clsx from 'clsx';

import { DownloadIcon } from '@shared/assets';

import { Typography } from '../Typography';

import type { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

type InfoProps = {
  title: ReactNode;
} & HTMLAttributes<HTMLElement>;

type InfoListItemProps = {
  title: ReactNode;
  description?: ReactNode;
  required?: boolean;
};

type InfoListProps = {
  title: string;
  children: ReactNode;
};

const InfoListItem: FC<InfoListItemProps> = ({
  title,
  description,
  required,
}) => (
  <li>
    <Typography className="!font-bold" variant="regular3">
      {title}
    </Typography>
    {required && (
      <>
        {' '}
        <Typography variant="regular3" color="critical">
          *
        </Typography>
      </>
    )}
    {description && (
      <Typography variant="regular3"> — {description};</Typography>
    )}
  </li>
);

const InfoList: FC<InfoListProps> = ({ title, children }) => (
  <div>
    <Typography variant="regular3" color="secondary">
      {title}
    </Typography>
    <ul className="list-disc pl-4">{children}</ul>
  </div>
);

const InfoText: FC<PropsWithChildren> = ({ children }) => (
  <Typography variant="caption1" className="!text-decorative-blue">
    {children}
  </Typography>
);

const InfoLink: FC<PropsWithChildren<{ href: string }>> = ({
  children,
  href,
}) => {
  return (
    <div className="flex grow justify-end items-end">
      <a
        href={href}
        className="text-base flex items-center text-decorative-blue"
      >
        {children}
        <DownloadIcon className="ml-2" />
      </a>
    </div>
  );
};

export const Info = ({ title, className, children, ...props }: InfoProps) => (
  <article
    {...props}
    className={clsx(
      className,
      'bg-decorative-soft-white rounded-3xl p-8 border border-stroke-default flex flex-col gap-4',
    )}
  >
    <Typography variant="h3" className="!text-decorative-blue">
      {title}
    </Typography>
    {children}
  </article>
);

Info.List = InfoList;
Info.ListItem = InfoListItem;
Info.Text = InfoText;
Info.Link = InfoLink;
