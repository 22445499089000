export type FocusEmitterData<Meta = unknown> = {
  x: number;
  y: number;
  meta?: Meta;
};

export type Listener<Meta> = (coords: FocusEmitterData<Meta> | null) => void;

export type FocusEmitter<Meta = unknown> = {
  emit: (data: FocusEmitterData<Meta> | null) => void;
  subscribe: (fn: Listener<Meta>) => VoidFunction;
  getFocused: () => FocusEmitterData<Meta> | null;
};

export const createFocusEmitter = <Meta = unknown>(): FocusEmitter<Meta> => {
  let listeners: Listener<Meta>[] = [];
  let focused: FocusEmitterData<Meta> | null = null;

  const emit = (data: FocusEmitterData<Meta> | null) => {
    if (data === focused) return;

    focused = data;
    listeners.forEach((fn) => fn(focused));
  };

  const subscribe = (fn: Listener<Meta>) => {
    if (focused) fn(focused);
    listeners.push(fn);

    return () => {
      listeners = listeners.filter((listener) => listener !== fn);
    };
  };

  const getFocused = () => focused;

  return { emit, subscribe, getFocused };
};
