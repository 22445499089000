import { memo } from 'react';

import { isIconKind, useWidth } from '@shared/helpers';
import { Icon, Typography } from '@shared/ui';

import type { IconKind } from '@shared/helpers';
import type { FC, FormEvent, PropsWithChildren, ReactNode } from 'react';

export interface FormLayoutProps extends PropsWithChildren {
  title?: string;
  action: ReactNode;
  illustration?: ReactNode | IconKind;
}

interface OnSubmitProps {
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
}

const FormLayout: FC<FormLayoutProps & OnSubmitProps> = ({
  title,
  illustration,
  children,
  action,
  onSubmit,
}) => {
  const { isDesktop } = useWidth();

  return (
    <div className="flex overflow-auto">
      <form onSubmit={onSubmit} className="w-full max-w-120 space-y-8">
        {title && (
          <Typography variant="h2" className="leading-none">
            {title}
          </Typography>
        )}
        <div className="flex flex-wrap gap-4">{children}</div>
        <div className="flex gap-4">{action}</div>
      </form>
      {isDesktop && illustration && (
        <div className="flex grow items-center justify-center pl-15">
          {isIconKind(illustration) ? (
            <Icon kind={illustration} className="w-55" />
          ) : (
            illustration
          )}
        </div>
      )}
    </div>
  );
};

export default memo(FormLayout);
